export const API_URL = process.env.REACT_APP_API_URL;
const isLocalhost = window.location.hostname.includes("localhost");

export const APP_BUSINESS_URL = isLocalhost
  ? `http://localhost:${window.location.port}/business-page`
  : API_URL?.includes("staging")
  ? "https://staging.business.deexoptions.com/business-page"
  : "https://business.deexoptions.com/business-page";

export const APP_SINGLE_BUSINESS_URL = isLocalhost
  ? `http://localhost:${window.location.port}/payment-page`
  : API_URL?.includes("staging")
  ? "https://staging.business.deexoptions.com/payment-page"
  : "https://business.deexoptions.com/payment-page";

export const depCoins = [
  {
    id: 1,
    name: "Bitcoin",
    abbr: "BTC",
    networks: [
      {
        id: 12,
        name: "BTC",
      },
    ],
  },
  {
    id: 2,
    name: "Tether",
    abbr: "USDT",
    networks: [
      {
        id: 21,
        name: "BEP20",
      },
      {
        id: 22,
        name: "ERC20",
      },
      {
        id: 23,
        name: "TRC20",
      },
    ],
  },
  {
    id: 3,
    name: "Ethereum",
    abbr: "ETH",
    networks: [
      {
        id: 31,
        name: "BEP20",
      },
      {
        id: 32,
        name: "ERC20",
      },
    ],
  },
  {
    id: 4,
    name: "Doge",
    abbr: "DOGE",
    networks: [
      {
        id: 41,
        name: "DOGE",
      },
    ],
  },
  {
    id: 5,
    name: "Shiba Inu",
    abbr: "SHIB",
    networks: [
      {
        id: 51,
        name: "BEP20",
      },
      {
        id: 52,
        name: "ERC20",
      },
    ],
  },
];

export const options = [
  { value: "agriculture", label: "Agriculture" },
  { value: "arts_and_crafts", label: "Arts and Crafts" },
  { value: "automotive", label: "Automotive" },
  { value: "beauty_and_wellness", label: "Beauty and Wellness" },
  { value: "clothing", label: "Clothing" },
  { value: "construction", label: "Construction" },
  { value: "consulting", label: "Consulting" },
  { value: "education", label: "Education" },
  { value: "ecommerce", label: "E-Commerce" },
  { value: "energy", label: "Energy" },
  { value: "entertainment", label: "Entertainment" },
  { value: "environmental_services", label: "Environmental Services" },
  { value: "event_management", label: "Event Management" },
  { value: "fashion", label: "Fashion" },
  { value: "finance", label: "Finance" },
  { value: "food_and_beverage", label: "Food and Beverage" },
  { value: "gaming", label: "Gaming" },
  { value: "government", label: "Government" },
  { value: "healthcare", label: "Healthcare" },
  { value: "hospitality", label: "Hospitality" },
  { value: "insurance", label: "Insurance" },
  { value: "logistics", label: "Logistics" },
  { value: "manufacturing", label: "Manufacturing" },
  { value: "media", label: "Media" },
  { value: "non_profit", label: "Non-Profit" },
  { value: "pharmaceuticals", label: "Pharmaceuticals" },
  { value: "professional_services", label: "Professional Services" },
  { value: "real_estate", label: "Real Estate" },
  { value: "religious_institution", label: "Religiou Institution" },
  { value: "retail", label: "Retail" },
  { value: "sports", label: "Sports" },
  { value: "technology", label: "Technology" },
  { value: "telecommunications", label: "Telecommunications" },
  { value: "transportation", label: "Transportation" },
  { value: "travel", label: "Travel" },
];
