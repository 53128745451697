import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { IoClose } from "react-icons/io5";
import GetRates from "../../components/utils/GetRates";
import { APP_SINGLE_BUSINESS_URL } from "../../constant";
import { formatAmount, truncateWord } from "../../utils/wordFunctions";
import { FiCopy } from "react-icons/fi";

const SinglePaymentModal = ({ setOpenModal, modalData }: any) => {
  function formatDate(dateString: string): string {
    const date = new Date(dateString);

    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Adding ordinal suffix to the day (e.g., 1st, 2nd, 3rd, 4th)
    const ordinal = (n: number) =>
      n +
      ["th", "st", "nd", "rd"][
        n % 10 > 3 || Math.floor((n % 100) / 10) === 1 ? 0 : n % 10
      ];

    return `${ordinal(day)} ${month}, ${year}`;
  }
  const coinRate = modalData?.rates?.find(
    (rate: { abbr: string }) => rate.abbr?.toLowerCase() === "usdt"
  )?.rate;

  return (
    <div className="fixed inset-0  flex font-manrope justify-start items-start pt-24 bg-black bg-opacity-70  ">
      <div
        className={` w-11/12 mds:w-9/12 md:7/12  bg-white  rounded-xl mx-auto   lgss:w-2/5 xxl:w-1/3 `}
      >
        <div className="w-full flex justify-between  px-6 py-4 rounded-t-xl bg-gray-100 gap-6 items-center">
          <h4 className="text-gray-700 font-bold   text-[14px]">
            Payment modal
          </h4>
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            className="flex items-center gap-2 "
          >
            <IoClose className=" text-gray-900 text-[24px]" />
          </button>
        </div>
        <div className="w-full bg-white rounded-b-xl  p-6">
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Date
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              {formatDate(modalData?.processedAt)}
            </h4>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              To Address
            </h4>
            <div className="flex items-center gap-2">
              <h4 className="text-[14px] text-gray-600 text-right font-semibold">
                {truncateWord(modalData?.toAddress ?? "---")}
              </h4>
              <CopyToClipboard
                text={modalData?.toAddress}
                onCopy={() => {
                  toast.success("Address Copied");
                }}
              >
                <FiCopy className="text-[14px] text-gray-600" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Coin
            </h4>
            <h4 className="text-[14px] text-gray-600 uppercase text-right font-semibold">
              {modalData?.currency.toUpperCase()}
            </h4>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Network
            </h4>
            <h4 className="text-[14px] text-gray-600 uppercase text-right font-semibold">
              {modalData?.network}
            </h4>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Amount
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              {modalData?.amount ? formatAmount(modalData?.amount) : "----"}{" "}
              {modalData?.currency.toUpperCase()}
            </h4>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Amount in USD
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              ${" "}
              {modalData?.amount ? formatAmount(modalData?.usdtAmount) : "----"}
            </h4>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Naira Value
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              {modalData?.amount
                ? formatAmount(modalData?.amount * coinRate)
                : "----"}{" "}
              NGN
            </h4>
          </div>

          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Txn ID
            </h4>
            <div className="flex items-center gap-2">
              <h4 className="text-[14px] text-gray-600 text-right font-semibold">
                {truncateWord(modalData?.txid ?? "---")}
              </h4>
              <CopyToClipboard
                text={modalData?.txid}
                onCopy={() => {
                  toast.success("TransactionID Copied");
                }}
              >
                <FiCopy className="text-[14px] text-gray-600" />
              </CopyToClipboard>
            </div>
          </div>
          <div className="flex justify-between gap-4 mt-10 items-center w-full">
            <button
              onClick={() => {
                setOpenModal(false);
              }}
              className="rounded-xl w-full bg-secondary_button  flex justify-center items-center px-2 py-4 text-[14px] font-medium text-white"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePaymentModal;
