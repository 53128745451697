import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import ActivityTable from "./ActivityTable";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { API } from "../../constant/api";
import useAuthAxios from "../../utils/baseAxios";
import { errorMessage } from "../../utils/errorMessage";
import DeexPending from "../../components/DeexPending";
import { getDateRange } from "../../utils/GetDateRange";
import { formatAmount } from "../../utils/wordFunctions";
import { CSVLink } from "react-csv";
import { BiExport } from "react-icons/bi";
import { ClipLoader } from "react-spinners";

const Activity = () => {
  const [screen, setScreen] = useState<number>(1);
  const axiosInstance = useAuthAxios();
  const [filter, setFilter] = useState<string>("");

  const { current } = getDateRange(filter);

  const getBusinesses = async () => {
    const response = await axiosInstance.get(API.getBusinesses);
    return response?.data?.data.businesses;
  };

  const {
    data: businesses,
    error: error1,
    isLoading,
  } = useQuery({
    queryKey: ["get-businesses"],
    queryFn: getBusinesses,
    retry: 1,
  });

  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);
  const getBusiness = async () => {
    if (businesses && businesses.length > 0 && businesses[0]._id) {
      const response = await axiosInstance.get(
        API.getBusiness(
          businesses[0]?._id,
          current.from ?? "",
          current.to ?? ""
        )
      );
      return response.data.data;
    }
    return null;
  };

  const { data: business, error: error2 } = useQuery({
    queryKey: ["get-business"],
    queryFn: getBusiness,
    retry: 1,
    enabled: !!(businesses && businesses?.length > 0 && businesses[0]?._id),
  });

  useEffect(() => {
    if (error2) {
      const newError = error2 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error2]);
  const summary = business?.summary;

  const getPayments = async () => {
    const response = await axiosInstance.get(
      API.getTransaction(
        businesses[0]?._id,
        1,
        current.from ?? "",
        current.to ?? "",
        10
      )
    );
    return response?.data;
  };

  const { data: transactions, error: error3 } = useQuery({
    queryKey: ["get-mercahnt-transaction", current.from, current.to],
    queryFn: getPayments,
    retry: 1,
    enabled: !!(businesses && businesses?.length > 0 && businesses[0]?._id),
  });
  useEffect(() => {
    if (error3) {
      const newError = error3 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error3]);
  const totalDeposits = transactions?.data?.meta.totalTransactions || 0;
  const getFullPaymentsTxn = async () => {
    const response = await axiosInstance.get(
      API.getTransaction(
        businesses[0]?._id,
        1,
        current.from ?? "",
        current.to ?? "",
        totalDeposits
      )
    );
    return response?.data;
  };

  const { data: fullTxn, error: error4 } = useQuery({
    queryKey: [
      "get-mercahnt-transaction",
      totalDeposits,
      current.from,
      current.to,
    ],
    queryFn: getFullPaymentsTxn,
    retry: 1,
    enabled: !!totalDeposits,
  });
  useEffect(() => {
    if (error3) {
      const newError = error3 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
    if (error4) {
      const newError = error4 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error3, error4]);
  // console.log(fullTxn);

  const exportData = fullTxn?.data?.transactions?.map((data: any) => {
    // Find the USDT rate for the current deposit
    const coinRate = data?.rates?.find(
      (rate: { abbr: string }) => rate.abbr?.toLowerCase() === "usdt"
    )?.rate;

    // Calculate the Naira value
    const amountInNaira = coinRate ? data.usdtAmount * coinRate : 0;

    // Format the data for export
    return {
      txnid: `${data?.transaction?.id || data?.txid}`,
      coin: `${data?.asset?.name || data?.currency}`,
      transactionType: `${data?.transactionType}`,
      status: `${data?.status}`,
      recipient: `${data?.recipient?.accountName} - ${data?.recipient?.accountNumber}, ${data?.recipient?.bankName}`,
      amount: `${formatAmount(data?.amount || data?.asset.amount)} ${
        data?.asset?.name || data?.currency
      }`,
      amountInUsd: `${formatAmount(
        data?.usdtAmount || data?.asset?.amountInUSD
      )}`,
      amountInNaira: `${formatAmount(data?.payoutAmount || amountInNaira)}`,
      dateCreated: `${(() => {
        const dateString = data.processedAt || data?.createdAt;
        const date = new Date(dateString);

        const day = date.getDate();
        const ordinalSuffix = (day: number) => {
          switch (day) {
            case 1:
            case 21:
            case 31:
              return "st";
            case 2:
            case 22:
              return "nd";
            case 3:
            case 23:
              return "rd";
            default:
              return "th";
          }
        };

        const formattedDate = `${date.toLocaleString("default", {
          month: "short",
        })} ${day}${ordinalSuffix(
          day
        )}, ${date.getFullYear()}, ${date.toLocaleString("default", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}`;

        return formattedDate;
      })()}`,
    };
  });
  return (
    <div
      className={
        businesses?.length >= 1 || businesses
          ? "w-full font-manrope lgss:h-screen "
          : "w-full h-screen font-manrope"
      }
    >
      <Navbar />
      <div
        className={
          businesses?.length >= 1 || businesses
            ? "lgss:flex  lgss:flex-row font-manrope w-full overflow-auto bg-[#F1F3F5]   h-screen"
            : "lgss:flex  lgss:flex-row font-manrope w-full  bg-[#F1F3F5]  h-screen"
        }
      >
        <Sidebar />
        <div className="w-full h-full pt-16 overflow-auto px-6 pb-20  font-manrope  lgss:px-20  lgss:w-5/6">
          <div className="flex md:justify-between flex-col md:flex-row justify-start items-start md:tems-center">
            <h2 className="font-sora text-[24px] font-semibold text-[#3A4852]">
              Activity
            </h2>
          </div>
          <div className="bg-white mt-10 py-5 lgss:py-8 px-4 lgss:px-8">
            <div className="flex justify-between items-center ">
              <h4 className="text-[#3A4852] text-[18px] font-bold ">
                Overview
              </h4>
              <div className="flex justify-center items-center gap-4 ">
                <select
                  onChange={(e) => setFilter(e.target.value)}
                  value={filter}
                  className="px-4 border bg-transparent outline-none  rounded-xl font-semibold text-[#3A4852] text-[11px] border-gray-300  h-[36px]"
                >
                  <option value="">All Time</option>
                  <option value="today">Today</option>
                  <option value="thisWeek">This week</option>
                  <option value="last3Months">Last 3 months</option>
                </select>
              </div>
            </div>
            <div className="w-full mt-6 flex justify-start items-center flex-col lgss:flex-row">
              <div className="pb-4 border  border-gray-100 w-full lgss:w-1/3 shadow-sm rounded-xl bg-white px-4 pt-4">
                <h4 className="text-[14px]  text-secondary_button tracking-wider font-semibold">
                  TOTAL PAYOUTS RECEIVED
                </h4>
                <div className="w-full mt-4 h-[1px] bg-[#F1F3F5]" />
                <h2 className="text-[22px] font-sora font-bold text-[#3A4852] mt-2">
                  {`$${formatAmount(summary?.totalPayouts) ?? 0.0}`}
                </h2>
                <div className="w-full flex px-8 lgss:px-4 justify-between items-center rounded-full mt-4 h-[26px] bg-[#F7F8F9]">
                  <h4 className="text-[12px] lgss:text-[11px] font-bold text-[#617889]">
                    {`${formatAmount(summary?.totalPayoutsInNaira) ?? 0.0} NGN`}
                  </h4>
                  <div className=" w-[1px] bg-[#617889] h-[16px]" />
                  <h4 className="text-[12px] lgss:text-[11px] font-bold text-[#617889]">
                    {`${formatAmount(summary?.payoutsCount) ?? 0.0} `}
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white mt-10 py-5 lgss:py-8 px-4 lgss:px-8">
            <div className="w-full flex justify-between items-center">
              <h2 className=" text-[18px] font-bold text-[#3A4852]">
                Recent activities
              </h2>
              <CSVLink
                data={exportData ?? ""}
                filename={`transactions-list`}
                className="flex gap-1 items-center bg-secondary_button p-[11px] rounded-lg"
              >
                <BiExport className="text-[white]" />
                <h4 className="uppercase font-sora font-semibold mt-1 text-gray-200 text-[10px]">
                  Export
                </h4>
              </CSVLink>
            </div>
            <div className="w-full flex justify-between items-center mt-6">
              <div className="w-full md:w-8/12 lgss:w-[23%] xxl:w-[20%] bg-[#F1F3F5] mx-auto lgss:mx-0 h-[60px] flex p-2 items-center justify-center rounded-full ">
                <button
                  onClick={() => setScreen(1)}
                  className={
                    screen === 1
                      ? " text-[11px] font-bold text-white bg-gray-700 rounded-full h-full w-1/2"
                      : " text-[11px] font-bold text-gray-700  rounded-full h-full w-1/2"
                  }
                >
                  Transaction
                </button>
                <button
                  onClick={() => setScreen(2)}
                  className={
                    screen === 2
                      ? " text-[11px] font-bold text-white bg-gray-700 rounded-full h-full w-1/2"
                      : " text-[11px] font-bold text-gray-700  rounded-full h-full w-1/2"
                  }
                >
                  All Activities
                </button>
              </div>
            </div>
            <div className="mt-10">
              {!isLoading ? (
                businesses && businesses.length > 0 ? (
                  <ActivityTable
                    businessId={businesses[0]._id}
                    current={current}
                  />
                ) : (
                  <DeexPending />
                )
              ) : (
                <div className="w-full flex justify-center items-center">
                  <ClipLoader color="#0B84CB" size={80} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {!isLoading && (!businesses || businesses.length < 1) ? (
        <DeexPending />
      ) : null}{" "}
    </div>
  );
};

export default Activity;
