import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";

import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

import AddBanks from "./AddBanks";
import { noTransaction } from "../../assets/images";
import { API } from "../../constant/api";
import { useUser } from "../../context/user-context";
import useAuthAxios from "../../utils/baseAxios";
import { errorMessage } from "../../utils/errorMessage";
import BankDetail from "./BankDetail";

const BankDetails = ({ setOpenBankDetails, banks, businessID }: any) => {
  const [openAddBank, setOpenAddBank] = useState<boolean>(false);
  const [openBank, setOpenBank] = useState<boolean>(false);
  const [bankId, setBankId] = useState<string>("");
  const axiosInstance = useAuthAxios();

  const getAllBanks = async () => {
    const response = await axiosInstance.get(API.getBanks);
    return response.data;
  };

  const { data: allBanks, error: error1 } = useQuery({
    queryKey: ["all-banks"],
    queryFn: getAllBanks,
    retry: 1,
  });
  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);
  // console.log(banks);
  return (
    <div className="fixed inset-0 z-50  flex font-manrope justify-start items-start pt-16 pb-16 bg-black overflow-auto bg-opacity-40  backdrop-blur-sm">
      <div
        className={` w-11/12 mds:w-8/12 md:7/12 border  bg-white  rounded-xl mx-auto p-6     lgss:w-2/5 xxl:w-1/3 `}
      >
        <div className="w-full flex justify-start gap-6 items-center">
          <button
            onClick={() => {
              setOpenBankDetails(false);
            }}
          >
            <BsArrowLeft className="text-black  text-[20px]" />
          </button>
          <h4 className="text-gray-900  font-sora font-semibold text-[20px]">
            Bank Accounts
          </h4>
        </div>
        <div
          className={
            banks?.length >= 2
              ? "mt-8 w-full h-[400px] overflow-auto"
              : "mt-8 w-full "
          }
        >
          <div className={"w-full  py-4  "}>
            {banks?.length < 1 ? (
              <div className="flex flex-col justify-center mt-16 lgss:mt-24 items-center">
                <img src={noTransaction} alt="" />

                <h4 className="font-bold text-[#869AA9] pt-2">
                  No default bank added
                </h4>
                <p className=" text-[#869AA9] pt-2 text-[12px]">
                  Kindly add bank details
                </p>
              </div>
            ) : (
              <div className="w-full grid grid-cols-1  gap-4">
                {banks
                  ?.sort((a: any, b: any) => b.isDefault - a.isDefault)
                  ?.map((bank: any, index: any) => (
                    <div
                      key={index}
                      onClick={() => {
                        setBankId(bank._id);

                        setTimeout(() => {
                          setOpenBank(true);
                        }, 400);
                      }}
                      className={
                        bank.isDefault
                          ? "  px-2 py-10 bg-defaultBankBg cursor-pointer flex flex-col justify-center bg-cover rounded-lg items-center"
                          : "  px-2 py-10 bg-noDefaultBankBg cursor-pointer flex flex-col justify-center bg-cover rounded-lg items-center"
                      }
                    >
                      <div className="flex justify-between gap-3 items-center">
                        <h4 className="font-semibold text-[#0B75C2] text-[16px]">
                          {bank.accountName}
                        </h4>
                        {/* <input
                  type="radio"
                  name=""
                  className="w-[20px] h-[20px]"
                  id=""
                /> */}
                      </div>
                      <h5 className="text-[#010D16] text-[15px] font-bold pt-2">
                        {bank.accountNumber}
                      </h5>
                      {allBanks?.data
                        .filter(
                          (allBank: any) => allBank.code === bank.bankCode
                        )
                        .map((bank: any, index: any) => (
                          <h5
                            key={index}
                            className="text-[#010D16] font-medium  text-[13px] pt-2"
                          >
                            {bank.name}
                          </h5>
                        ))}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
        <h4 className=" text-center text-gray-500 text-[14px] font-medium">
          This is your withdrawal bank account and can be useful for easy
          funding of cash wallet
        </h4>
        <button
          onClick={() => {
            setOpenAddBank(true);
          }}
          className="mt-8 bg-secondary_button text-white font-medium text-[14px] h-[48px] rounded-xl w-full"
        >
          Add bank
        </button>
      </div>
      {openAddBank && (
        <AddBanks setOpenAddBank={setOpenAddBank} businessID={businessID} />
      )}
      {openBank && (
        <BankDetail
          setOpenBank={setOpenBank}
          bankId={bankId}
          banks={banks}
          businessID={businessID}
        />
      )}
    </div>
  );
};

export default BankDetails;
