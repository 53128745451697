import React, { useEffect, useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import { useAuth } from "../context/auth-context";
import { noTransaction } from "../assets/images";
import { useInView } from "react-intersection-observer";
import ClipLoader from "react-spinners/ClipLoader";
import useAuthAxios from "../utils/baseAxios";
import { useQuery } from "@tanstack/react-query";
import { API } from "../constant/api";
import toast from "react-hot-toast";
import { errorMessage } from "../utils/errorMessage";

const NotificationModal = ({ setIsNotified }: any) => {
  const { token } = useAuth();

  const [page, setPage] = useState<number>(1);
  const divRef = useRef<HTMLDivElement | null>(null);
  const [ref, inView] = useInView();
  const axiosinstance = useAuthAxios();

  const handleFetchN = async () => {
    const response = await axiosinstance.get(API.getNotifications(page));
    return response.data;
  };
  const {
    data,
    error: error2,
    isLoading,
  } = useQuery({
    queryKey: ["notifications", page], // Unique key for this query
    queryFn: handleFetchN,
    enabled: !!token, // Ensure the query only runs if a token is available
  });

  const notifications = data?.data.inAppNotifications || [];
  const unreadNotify = data?.data.meta.noOfUnreadInAppNotifications || 0;
  const totalPages = data?.data.meta.totalPages || 0;
  useEffect(() => {
    if (error2) {
      const newError = error2 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error2]);
  useEffect(() => {
    if (inView) {
      if (page < totalPages) {
        setPage((prev) => prev + 1);
      }
    }
  }, [inView, totalPages]);

  function formatDate(timestamp: string | number | Date) {
    const now = new Date();
    const date = new Date(timestamp);

    const isToday = date.toDateString() === now.toDateString();
    const isYesterday =
      date.toDateString() ===
      new Date(now.setDate(now.getDate() - 1)).toDateString();

    if (isToday) {
      return "Today " + formatTime(date);
    } else if (isYesterday) {
      return "Yesterday " + formatTime(date);
    } else {
      return formatDateOnly(date) + " , " + formatTime(date);
    }
  }

  function formatTime(date: Date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  function formatDateOnly(date: Date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
  }

  return (
    <div className="fixed w-full inset-0 flex font-manrope overflow-auto pb-16 z-50 justify-end px-6 xs:px-10 items-start pt-[70px] bg-[#000000]  bg-opacity-10 ">
      <div className="relative w-full lgss:left-[65%]">
        <div className="absolute -top-3 left-[88%] lgss:left-[28%] transform -translate-x-1/2">
          <div className="w-6 h-6 bg-white  transform rotate-45 "></div>
        </div>
        <div className="w-full lgss:w-1/3  transition-all ease-in duration-1000   rounded-xl bg-white  overflow-auto scrollbar-thumb-[#60B7F6]    scrollbar-thin h-[500px] xs:h-[610px] lgss:h-[570px]">
          <div className="flex px-10 pt-4 shadow-md pb-4 justify-between items-center">
            <div className="flex justify-center items-center gap-2 ">
              <h4 className="font-semibold text-[15px] text-paragraph ">
                Notifications
              </h4>
              {unreadNotify >= 1 && (
                <div
                  className="w-[18px] h-[18px] xs:w-[24px] xs:h-[24px] rounded-full flex justify-center items-center text-secondary_button bg-[#D4EBFD] font-semibold
               text-[12px]"
                >
                  {unreadNotify}
                </div>
              )}
            </div>
            <FaTimes
              onClick={() => {
                setIsNotified((prev: any) => !prev);
              }}
              className="text-[#13181B]  text-[20px] cursor-pointer"
            />
          </div>
          <div
            ref={divRef}
            className="w-full px-6 pt-4 pb-4 h-[530px] lgss:h-[490px] "
          >
            {notifications.length >= 1 ? (
              <>
                <div className="w-full">
                  {notifications.map((notification: any, index: any) => {
                    const formattedDate = formatDate(notification.createdAt);
                    return (
                      <div
                        className={
                          notification.readAt
                            ? "mt-4 "
                            : "mt-4 bg-[#FCF3E866] p-2"
                        }
                        key={index}
                      >
                        <div className="text-[13px] flex justify-start items-center gap-2 font-bold text-[#3A4852] ">
                          {!notification.readAt && (
                            <div className="w-[8px] h-[8px] rounded-full bg-[#F28A0F]"></div>
                          )}{" "}
                          {notification.title}
                        </div>
                        <h5 className="text-[13px] font-medium mt-3 text-[#617889]">
                          {notification.content}
                        </h5>
                        <h5 className="text-[11px] font-medium mt-4 text-[#617889]">
                          {formattedDate}
                        </h5>
                        <div className="bg-gray-300 h-[1px]  w-full rounded-full mt-4" />
                      </div>
                    );
                  })}

                  <div ref={ref} className=""></div>
                </div>
              </>
            ) : (
              !isLoading && (
                <div className="flex flex-col justify-center mt-16 lgss:mt-24 items-center">
                  <img src={noTransaction} alt="" />

                  <h4 className="font-bold text-[#869AA9] pt-2">
                    No notification
                  </h4>
                  <p className=" text-[#869AA9] pt-2 text-[12px]">
                    All your notifications will show up here
                  </p>
                </div>
              )
            )}
            {isLoading && (
              <div className="mt-6 flex justify-center items-center">
                <ClipLoader color="#0B75C2" size={60} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModal;
