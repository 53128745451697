import React, { createContext, useContext, useEffect, useState } from "react";
import { ContextValueType, PropsType } from "../types/index";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { API } from "../constant/api";
import useAuthAxios from "../utils/baseAxios";
import { errorMessage } from "../utils/errorMessage";
import { useAuth } from "./auth-context";
import { useLocation } from "react-router-dom";
export const UserContext = createContext<ContextValueType>({});
export const useUser = () => useContext(UserContext);

export const UserProvider: React.FC<PropsType> = ({ children }) => {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") ? localStorage.getItem("theme") : null
  );
  const location = useLocation();
  const [isNotified, setIsNotified] = useState<boolean>(false);
  const { token } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState<string>("/dashboard");
  const [resetEmail, setResetEmail] = useState<string>("");
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [bnNumber, setBnNumber] = useState("");
  const [identityUrl, setIdentityUrl] = useState("");
  const [addressUrl, setAddressUrl] = useState("");
  const [cacUrl, setCacUrl] = useState("");
  const [businessVerified, setBusinessVerified] = useState(false);
  const [documentNotloaded, setDocumentNotloaded] = useState(false);
  const [activeStatus, setActiveStatus] = useState<number>(1);
  const [paymentRefId, setPaymentRefId] = useState<string>("");
  const [paymentName, setPaymentName] = useState<string>("");
  const axiosInstance = useAuthAxios();

  const darkQuery = window.matchMedia("(prefers-color-scheme: dark)");
  const element = document.documentElement;

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setActiveStatus(1);
    } else if (location.pathname === "/payment") {
      setActiveStatus(3);
    } else if (location.pathname === "/sales") {
      setActiveStatus(5);
    } else if (location.pathname === "/activity") {
      setActiveStatus(4);
    }
  }, [location.pathname]);
  const getUserDetails = async () => {
    const response = await axiosInstance.get(API.getUser);
    return response.data.data;
  };

  const {
    data: userDetails,
    error: error1,
    refetch,
  } = useQuery({
    queryKey: ["userDetails"],
    queryFn: getUserDetails,
    enabled: false,
    retry: 1,
  });

  useEffect(() => {
    if (error1) {
      // console.log(error1);
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);
  useEffect(() => {
    if (token && token !== "none") {
      refetch();
    }
  }, [refetch, token]);

  useEffect(() => {
    const updateTheme = () => {
      if (
        localStorage.theme === "dark" ||
        (!("theme" in localStorage) && darkQuery.matches)
      ) {
        setTheme("dark");
        element.classList.add("dark");
        updateMetaTags("dark");
      } else {
        setTheme("light");
        element.classList.remove("dark");
        updateMetaTags("light");
      }
    };

    const updateMetaTags = (theme: string) => {
      const themeColorMeta = document.querySelector('meta[name="theme-color"]');
      if (themeColorMeta) {
        themeColorMeta.setAttribute(
          "content",
          theme === "dark" ? "#0D0D0D" : "#FFFFFF"
        );
      }
    };

    updateTheme();
    darkQuery.addEventListener("change", (e) => {
      if (!("theme" in localStorage)) {
        if (e.matches) {
          setTheme("dark");
          updateMetaTags("dark");
        } else {
          setTheme("light");
          updateMetaTags("light");
        }
      }
    });

    return () => {
      darkQuery.removeEventListener("change", updateTheme);
    };
  }, [darkQuery, element.classList]);
  useEffect(() => {
    const themeColorMeta = document.querySelector('meta[name="theme-color"]');
    if (themeColorMeta) {
      themeColorMeta.setAttribute(
        "content",
        theme === "dark" ? "#0D0D0D" : "#FFFFFF"
      );
    }
  }, [theme]);

  useEffect(() => {
    switch (theme) {
      case "dark":
        setTheme("dark");
        element.classList.add("dark");
        localStorage.setItem("theme", "dark");
        break;

      case "light":
        setTheme("light");
        element.classList.remove("dark");
        localStorage.setItem("theme", "light");
        break;
      default:
        localStorage.removeItem("theme");

        break;
    }
  }, [theme]);

  darkQuery.addEventListener("change", (e) => {
    if (!("theme" in localStorage)) {
      if (e.matches) {
        element.classList.add("dark");
      } else {
        element.classList.remove("dark");
      }
    }
  });
  return (
    <UserContext.Provider
      value={{
        theme,
        setTheme,
        setIsOpen,
        isOpen,
        isNotified,
        setIsNotified,
        userDetails,
        refetch,
        page,
        setPage,
        resetEmail,
        setResetEmail,
        uploadModal,
        setUploadModal,
        bnNumber,
        setBnNumber,
        identityUrl,
        setIdentityUrl,
        addressUrl,
        setAddressUrl,
        cacUrl,
        setCacUrl,
        setDocumentNotloaded,
        documentNotloaded,
        businessVerified,
        setBusinessVerified,
        successModal,
        setSuccessModal,
        paymentRefId,
        setPaymentRefId,
        activeStatus,
        setActiveStatus,
        paymentName,
        setPaymentName,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
