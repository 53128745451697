import React from "react";
import { IoClose } from "react-icons/io5";
import { formatAmount } from "../../utils/wordFunctions";

const ActivityModal = ({ setOpenModal, modalData }: any) => {
  function formatDate(dateString: string): string {
    const date = new Date(dateString);

    const day = date.getDate();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    // Adding ordinal suffix to the day (e.g., 1st, 2nd, 3rd, 4th)
    const ordinal = (n: number) =>
      n +
      ["th", "st", "nd", "rd"][
        n % 10 > 3 || Math.floor((n % 100) / 10) === 1 ? 0 : n % 10
      ];

    return `${ordinal(day)} ${month}, ${year}`;
  }
  return (
    <div className="fixed inset-0  flex font-manrope justify-start items-start pt-16 pb-16 overflow-auto bg-black bg-opacity-70  ">
      <div
        className={` w-11/12 mds:w-9/12 md:7/12  bg-white  rounded-xl mx-auto   lgss:w-[35%] xxl:w-1/3 `}
      >
        <div className="w-full flex justify-between  px-4 xs:px-6 py-4 rounded-t-xl bg-gray-100 gap-6 items-center">
          <h4 className="text-gray-700 font-bold   text-[14px]">
            Payment modal
          </h4>
          <button
            onClick={() => {
              setOpenModal(false);
            }}
            className="flex items-center gap-2 "
          >
            <IoClose className=" text-gray-900 text-[24px]" />
          </button>
        </div>
        <div className="w-full bg-white rounded-b-xl  p-6">
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Date
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              {formatDate(modalData?.createdAt || modalData?.processedAt)}
            </h4>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Type
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              {modalData?.transactionType === "payouts"
                ? "Payout"
                : modalData?.transactionType === "crypto-deposit"
                ? "Deposit"
                : ""}
            </h4>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Amount
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              {modalData?.transactionType === "payouts"
                ? `${formatAmount(
                    modalData?.asset.amount
                  )} ${modalData?.asset?.name.toUpperCase()}`
                : modalData?.transactionType === "crypto-deposit"
                ? `${formatAmount(
                    modalData?.amount
                  )} ${modalData?.currency.toUpperCase()}`
                : "---"}
            </h4>
          </div>
          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Naira Value
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              {modalData?.transactionType === "payouts"
                ? formatAmount(modalData?.payoutAmount)
                : modalData?.transactionType === "crypto-deposit"
                ? "0.00"
                : "---"}{" "}
              NGN
            </h4>
          </div>
          {modalData?.transactionType === "payouts" && (
            <>
              <div className="flex justify-between py-4 gap-4 items-center w-full">
                <h4 className="text-[14px] text-gray-500 text-left font-medium">
                  Bank Details
                </h4>
                <h4 className="text-[14px] text-gray-600 text-right font-semibold">
                  {modalData?.recipient?.accountNumber} -{" "}
                  {modalData?.recipient?.bankName}
                </h4>
              </div>
              <div className="flex justify-between py-4 gap-4 items-center w-full">
                <h4 className="text-[14px] text-gray-500 text-left font-medium">
                  Bank name
                </h4>
                <h4 className="text-[14px] text-gray-600 text-right font-semibold">
                  {modalData?.recipient?.accountName}
                </h4>
              </div>
            </>
          )}

          <div className="flex justify-between py-4 gap-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Status
            </h4>
            <div
              className={
                modalData?.transactionType === "payouts"
                  ? `${
                      modalData?.status === "PENDING"
                        ? "text-[#CB9200]  text-[14px] font-bold capitalize"
                        : modalData?.status === "IN PROGRESS"
                        ? "text-[#60B7F6]  text-[14px] font-bold capitalize"
                        : modalData?.status === "COMPLETED"
                        ? "text-[#03C668]  text-[14px] font-bold capitalize"
                        : modalData?.status === "FAILED"
                        ? "text-red-700 text-[14px] font-bold capitalize"
                        : ""
                    }`
                  : modalData?.transactionType === "crypto-deposit"
                  ? "text-[#03C668]  text-[14px] font-bold capitalize"
                  : "---"
              }
            >
              {modalData?.transactionType === "payouts"
                ? modalData.status
                : modalData?.transactionType === "crypto-deposit"
                ? "COMPLETED"
                : "---"}
            </div>
          </div>
          <div className="flex justify-between gap-4 py-4 items-center w-full">
            <h4 className="text-[14px] text-gray-500 text-left font-medium">
              Transaction ID
            </h4>
            <h4 className="text-[14px] text-gray-600 text-right font-semibold">
              #{modalData?._id}
            </h4>
          </div>
          {/* <div className="flex justify-between gap-4 mt-10 items-center w-full">
            <button
              onClick={() => {}}
              className="rounded-xl w-1/2 bg-secondary_button  flex justify-center items-center px-2 py-4 text-[14px] font-medium text-white"
            >
              Close
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ActivityModal;
