import React, { useEffect, useState } from "react";
import Register from "./Register";
import { useAuth } from "../context/auth-context";
import { smallLogo } from "../assets/images";
import axios from "axios";

import { TailSpin } from "react-loader-spinner";
import OtpInput from "react-otp-input";
import { API_URL } from "../constant";
import { useLocation, useNavigate } from "react-router-dom";
import platform from "platform";
import toast from "react-hot-toast";

const TwoFA = () => {
  const { authenticate } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const info = location?.state;
  const [loading, setLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [sendOtp, setSendOtp] = useState<boolean>(false);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(59);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);
  const handleChange = (otp: React.SetStateAction<string>) => {
    setOtp(otp);
  };
  const getUniqueId = () => {
    // Generate a pseudo-unique identifier based on user agent and screen dimensions
    const uniqueId = `${navigator.userAgent}-${window.screen.width}x${window.screen.height}`;
    return uniqueId;
  };

  const getDeviceName = () => {
    // Use navigator.userAgent to get information about the browser
    const userAgent = navigator.userAgent;

    // Detect the browser name based on the userAgent string
    let browserName;

    if (userAgent.indexOf("Firefox") !== -1) {
      browserName = "Mozilla Firefox";
    } else if (userAgent.indexOf("Chrome") !== -1) {
      browserName = "Google Chrome";
    } else if (userAgent.indexOf("Safari") !== -1) {
      browserName = "Apple Safari";
    } else if (userAgent.indexOf("Edge") !== -1) {
      browserName = "Microsoft Edge";
    } else if (
      userAgent.indexOf("MSIE") !== -1 ||
      userAgent.indexOf("Trident/") !== -1
    ) {
      browserName = "Internet Explorer";
    } else {
      browserName = "Unknown Browser";
    }

    return browserName;
  };
  const getOperatingSystem = () => {
    let os = platform.os;

    return os;
  };
  const uniqueId = getUniqueId();
  const deviceName = getDeviceName();
  const operatingSystem = getOperatingSystem();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!loading) {
      setLoading(true);
      axios
        .post(`${API_URL}api/auth/complete-signin`, {
          email: info.email,
          password: info.password,
          otp,
          deviceInfo: {
            name: deviceName,
            uniqueId: uniqueId,
            os: operatingSystem?.family,
          },
        })
        .then((r: any) => {
          // setLoading(false);
          toast.success("You are logged in. Happy trading!!!");
          setLoading(false);

          setTimeout(() => {
            authenticate(r.data.data.token);
            navigate(info.from);
          }, 3000);
        })
        .catch((e: any) => {
          toast.error(e.response.data.message);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    if (seconds > 0 || minutes > 0) {
      setSendOtp(true);
    } else {
      setSendOtp(false);
    }
  }, [seconds, minutes]);

  const resendOtp = () => {
    setLoading(false);

    axios
      .post(`${API_URL}api/auth/signin`, {
        email: info.email,
        password: info.password,
        "2faMethod": "email",
        deviceInfo: {
          name: deviceName,
          uniqueId: uniqueId,
          os: operatingSystem?.family,
        },
      })
      .then((r: any) => {
        toast.success("OTP sent to your mail");
      })
      .catch((e: any) => {
        toast.error(e.response.data.message);
      });

    setMinutes(0);
    setSeconds(59);
  };
  return (
    <div className="w-full lgss:h-full pt-20  flex flex-col justify-center items-center lgss:pt-0 lgss:w-3/5">
      <div>
        <img src={smallLogo} className="lgss:hidden" alt="" />
      </div>
      <div className="flex w-full pt-16 pb-10 px-4 lgss:pb-0 lgss:pt-0 flex-col justify-center lgss:px-28  items-center lgss:items-start lg:px-[150px]">
        <h2 className="text-text_black font-sora text-2xl font-bold pt-4 lgss:pt-0">
          2FA Authentication (Email)
        </h2>
        <h4 className="font-manrope  text-text_black font-medium text-sm pt-6">
          Kindly input the OTP sent to your mail.
        </h4>
        <form
          onSubmit={handleSubmit}
          className="w-10/12 lgss:w-11/12 flex flex-col justify-center items-center lgss:items-start  "
        >
          <div className="mt-6 px-4 flex w-full justify-center lgss:justify-start lgss:px-0  items-center  ">
            <OtpInput
              value={otp}
              onChange={handleChange}
              numInputs={6}
              isInputNum
              isInputSecure
              inputStyle={{
                width: "50px",
                marginLeft: "4px",
                marginRight: "4px",
                height: "56px",
                borderWidth: "2px",
                borderColor: "#AEBCC6",
                borderStyle: "solid",
                backgroundColor: "transparent",
                borderRadius: "0.75rem",
                textAlign: "center",
                fontWeight: "600",
                fontSize: "1.25rem",
                appearance: "none",
                transition: "all 0.15s ease-in-out",
                outline: "none",
              }}
              focusStyle={{
                borderColor: "#0B84CB",
              }}
              shouldAutoFocus
            />
          </div>

          <div className="flex justify-center mt-4 items-center">
            {seconds > 0 || minutes > 0 ? (
              <p className="text-paragraph   font-bold text-[14px]">
                Time Remaining:{" "}
                <span className="text-secondary_button">
                  {minutes < 10 ? `0${minutes}` : minutes}:{" "}
                  {seconds < 10 ? `0${seconds}` : seconds}
                </span>
              </p>
            ) : (
              <p className="font-bold  text-paragraph  text-[14px]">
                Didn't recieve code?
              </p>
            )}
            {!sendOtp && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  resendOtp();
                }}
                className="text-secondary_button  flex justify-center items-center ml-2 font-bold"
              >
                Resend OTP
              </button>
            )}
          </div>
          <h4 className="text-[14px] mt-8  text-[#869AA9] font-bold">
            *If you don't get a mail in your inbox, kindly check your spam or
            promotions tab*
          </h4>

          <button
            onClick={() => handleSubmit}
            className="w-full  lg:w-4/5 lg:mx-0 h-[60px]  flex justify-center items-center font-manrope mx-auto  font-medium rounded-xl text-white bg-secondary_button mt-8 lgss:mt-8"
          >
            {loading ? (
              <TailSpin color="#FFFFFF" height={30} width={30} />
            ) : (
              "Verify it's you"
            )}
          </button>
          {info.emailAuth === true && info.authApp === true ? (
            <h4
              className="cursor-pointer text-secondary_button font-bold text-[14px] mt-2"
              onClick={() => {
                navigate("/auth-select", {
                  state: {
                    email: info.email,
                    password: info.password,
                    from: info.from,
                    authApp: info.authApp,
                    emailAuth: info.emailAuth,
                  },
                });
              }}
            >
              Try another method
            </h4>
          ) : null}
        </form>
      </div>
    </div>
  );
};
const TwoFaPage = () => (
  <div className="lgss:flex lgss:flex-row h-screen ">
    <Register />
    <TwoFA />
  </div>
);
export default TwoFaPage;
