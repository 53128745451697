import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { FiCopy } from "react-icons/fi";
import { RiSearchLine } from "react-icons/ri";
import PaymentTable from "./PaymentTable";
import CreatePaymentPage from "./CreatePaymentPage";
import PaymentSuccess from "./PaymentSuccess";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { API } from "../../constant/api";
import useAuthAxios from "../../utils/baseAxios";
import { errorMessage } from "../../utils/errorMessage";
import DeexPending from "../../components/DeexPending";
import { APP_BUSINESS_URL } from "../../constant";
import CopyToClipboard from "react-copy-to-clipboard";
import PaymentDeets from "./PaymentDeets";
import { useUser } from "../../context/user-context";
import AddBanks from "../banks/AddBanks";
import { ClipLoader } from "react-spinners";

const Payment = () => {
  const { userDetails } = useUser();
  const [openNewPage, setOpennNewPage] = useState<boolean>(false);
  const [openSuccess, setOpenSuccess] = useState<boolean>(false);
  const [itemName, setItemName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [redirectLink, setRedirectLink] = useState<string>("");
  const [optionalEmail, setOptionalEmail] = useState<string>("");
  const [seoImageURL, setSeoImageURL] = useState<string>("");
  const [allocateCheck, setAllocateCheck] = useState(false);
  const [dataCheck, setDataCheck] = useState(false);
  const [query, setQuery] = useState("");
  const [referenceID, setReferenceID] = useState<string>("");
  const [openTxn, setOpenTxn] = useState<boolean>(false);
  const axiosInstance = useAuthAxios();
  const banks = userDetails?.user?.banks;
  const [openAddBank, setOpenAddBank] = useState<boolean>(false);
  const getBusinesses = async () => {
    const response = await axiosInstance.get(API.getBusinesses);
    return response?.data?.data.businesses;
  };

  const {
    data: businesses,
    error: error1,
    isLoading,
  } = useQuery({
    queryKey: ["get-businesses"],
    queryFn: getBusinesses,
    retry: 1,
  });

  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);
  const urlNew = `${APP_BUSINESS_URL}/${businesses && businesses[0]?._id}`;
  return (
    <div className="w-full font-manrope h-screen">
      <Navbar />
      <div className="lgss:flex  lgss:flex-row font-manrope bg-[#F1F3F5] h-screen">
        <Sidebar />
        {openTxn ? (
          <PaymentDeets setOpenTxn={setOpenTxn} />
        ) : (
          <div className="w-full h-full overflow-auto pt-16 px-6 pb-20  font-manrope  lgss:px-20  lgss:w-5/6">
            <div className="flex md:justify-between flex-col md:flex-row justify-start items-start md:tems-center">
              <h2 className="font-sora text-[24px] font-semibold text-[#3A4852]">
                Payments
              </h2>
              <div className="flex items-center mt-4 md:mt-0 w-full md:w-1/2 xl:w-1/3 gap-4">
                <div className="rounded-xl w-1/2 bg-white border flex justify-center items-center  border-gray-100 px-2 py-3 text-[13px] font-medium text-gray-700">
                  {urlNew.slice(0, 18)}
                </div>
                <CopyToClipboard
                  text={urlNew}
                  onCopy={() => {
                    toast.success("Business page URL copied");
                  }}
                >
                  <button className="rounded-xl w-1/2 bg-[#D4EBFD]  flex justify-center items-center gap-2 px-2 py-3 text-[13px] font-medium text-[#0B75C2]">
                    <FiCopy /> Payment Link
                  </button>
                </CopyToClipboard>
              </div>
            </div>
            <div className="mt-12 bg-white w-full p-4 mds:p-6 lgss:p-10 xxl:p-20">
              <div className="w-full flex flex-col lgss:flex-row justify-between items-center">
                <div className="w-full lgss:w-2/5  xxl:w-1/3 flex justify-start items-center rounded-xl gap-3 px-2 py-3 bg-[#F1F3F5]">
                  <RiSearchLine className="text-gray-500 text-[20px]" />
                  <input
                    type="search"
                    onChange={(e) => setQuery(e.target.value.toLowerCase())}
                    placeholder="Search"
                    className="outline-none w-10/12 text-gray-700 text-[15px] bg-transparent"
                  />
                </div>
                <div className="flex items-center w-full lgss:w-2/5 xxl:w-[25%] mt-4 lgss:mt-0  gap-4">
                  <button className="rounded-xl w-1/2 bg-transparent border flex justify-center items-center  border-secondary_button px-2 py-3 text-[14px] font-medium text-secondary_button">
                    Create invoice
                  </button>
                  <button
                    onClick={() => {
                      if (banks.length >= 1) {
                        setOpennNewPage(true);
                      } else {
                        setOpenAddBank(true);
                      }
                    }}
                    className="rounded-xl w-1/2 bg-secondary_button  flex justify-center items-center px-2 py-3 text-[14px] font-medium text-white"
                  >
                    Create New Page
                  </button>
                </div>
              </div>
              {!isLoading ? (
                businesses && businesses.length > 0 ? (
                  <PaymentTable
                    businessId={businesses[0]._id}
                    query={query}
                    setOpenTxn={setOpenTxn}
                  />
                ) : (
                  <DeexPending />
                )
              ) : (
                <div className="w-full mt-16 flex justify-center items-center">
                  <ClipLoader color="#0B84CB" size={80} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {openNewPage && (
        <CreatePaymentPage
          setOpennNewPage={setOpennNewPage}
          setItemName={setItemName}
          itemName={itemName}
          description={description}
          setDescription={setDescription}
          setSeoImageURL={setSeoImageURL}
          setAllocateCheck={setAllocateCheck}
          allocateCheck={allocateCheck}
          dataCheck={dataCheck}
          setDataCheck={setDataCheck}
          setRedirectLink={setRedirectLink}
          redirectLink={redirectLink}
          optionalEmail={optionalEmail}
          setOptionalEmail={setOptionalEmail}
          setOpenSuccess={setOpenSuccess}
          seoImageURL={seoImageURL}
          businessId={businesses[0]?._id}
          setReferenceID={setReferenceID}
        />
      )}
      {openSuccess && (
        <PaymentSuccess
          setOpenSuccess={setOpenSuccess}
          referenceID={referenceID}
        />
      )}
      {openAddBank && (
        <AddBanks
          setOpenAddBank={setOpenAddBank}
          businessID={businesses[0]?._id}
        />
      )}
      {!isLoading && (!businesses || businesses.length < 1) ? (
        <DeexPending />
      ) : null}
    </div>
  );
};

export default Payment;
