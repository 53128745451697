import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { toast } from "react-hot-toast";
import { ClipLoader } from "react-spinners";
import { API } from "../../constant/api";
import { useUser } from "../../context/user-context";
import useAuthAxios from "../../utils/baseAxios";
import { errorMessage } from "../../utils/errorMessage";

const AddBanks = ({ setOpenAddBank, businessID }: any) => {
  const { refetch } = useUser();
  const [bankCode, setBankCode] = useState<string>("");
  const [bankNumber, setBankNumber] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [bankName, setBankName] = useState<string>("");
  const [userBankName, setUserBankName] = useState<string>("");
  const queryClient = useQueryClient();

  const [isChecked, setIsChecked] = useState(false);
  const axiosInstance = useAuthAxios();
  const handleChange = (event: any) => {
    const selectedBank = allBanks?.data.find(
      (bank: any) => bank.name === event.target.value
    );
    if (selectedBank) {
      setBankCode(selectedBank.code);
      setBankName(selectedBank.name);
    }
  };

  const getAllBanks = async () => {
    const response = await axiosInstance.get(API.getBanks);
    return response.data;
  };

  const { data: allBanks, error: error1 } = useQuery({
    queryKey: ["all-banks"],
    queryFn: getAllBanks,
    retry: 1,
  });
  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);
  const getbankName = async () => {
    const response = await axiosInstance.get(
      API.verifyAccountNumber(bankNumber, bankCode)
    );
    return response.data;
  };
  const {
    data: userName,
    error: error2,
    isPending: loading,
  } = useQuery({
    queryKey: ["get-user-name"],
    queryFn: getbankName,
    retry: 1,
    enabled: bankNumber.length === 10 && !!bankCode,
  });
  //   console.log(userName);
  useEffect(() => {
    if (userName?.data.account_name && bankNumber.length === 10 && bankCode) {
      setUserBankName(userName?.data.account_name);
    }
  }, [bankCode, bankNumber.length, userName?.data.account_name]);
  useEffect(() => {
    if (error2) {
      const newError = error2 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error2]);

  const addBank = async ({ bankCode, accountNumber, isDefault }: any) => {
    const response = await axiosInstance.put(
      API.saveBusinessAccountDetails(businessID),
      {
        accountNumber,
        bankCode,
        isDefault,
      }
    );
    return response.data;
  };

  const completeAddBank = useMutation({
    mutationFn: addBank,
    onSuccess: (r) => {
      toast.success(r.message);
      queryClient.invalidateQueries({
        queryKey: ["userDetails"],
      });
      setTimeout(() => {
        refetch();
        setOpenAddBank(false);
      }, 1500);
    },
    onError: (e) => {
      // console.log(e);
      const err = e as any;
      toast.error(errorMessage(err?.message || err?.data?.message));
    },
  });

  useEffect(() => {
    if (!bankName || bankNumber.length !== 10 || !userName) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [bankName, bankNumber.length, userName]);
  return (
    <div className="fixed inset-0 z-50 flex font-manrope justify-start items-start pt-16 pb-16 bg-black overflow-auto bg-opacity-40   backdrop-blur-sm">
      <div
        className={` w-11/12 mds:w-8/12 md:7/12 border  bg-white  rounded-xl mx-auto p-6     lgss:w-2/5 xxl:w-1/3 `}
      >
        <div className="w-full flex justify-start gap-6 items-center">
          <button
            onClick={() => {
              setOpenAddBank(false);
              setBankNumber("");
              setBankCode("");
            }}
          >
            <BsArrowLeft className="text-black  text-[20px]" />
          </button>
          <h4 className="text-gray-900  font-sora font-semibold text-[20px]">
            Add Bank
          </h4>
        </div>
        <div className="mt-8 w-full">
          <div className={`w-full `}>
            <label className="text-gray-700  ">Account number</label>
            <input
              type="number"
              value={bankNumber}
              onChange={(e) => setBankNumber(e.target.value)}
              placeholder="Enter your account number"
              className="w-full  text-gray-700  h-[60px] mt-2 mb-2 lgss:mt-4 outline-none text-[15px] border border-gray-400 bg-[#F7F8F9] px-4 spin-button-none rounded-xl "
            />
            <label className="text-gray-700   ">Bank name</label>
            <select
              onChange={handleChange}
              className="w-full  text-gray-700  h-[60px] mt-2 lgss:mt-4 outline-none text-[15px] border border-gray-400 bg-[#F7F8F9] pr-2 pl-2  rounded-xl"
            >
              <option>Select your bank</option>
              {allBanks?.data.map((bank: any, index: any) => (
                <option key={index} className="p-4">
                  {bank.name}
                </option>
              ))}
            </select>
            <h4 className="text-[14px] mt-2 font-bold text-[#d39c54]">
              {loading ? (
                <ClipLoader color="#FFFFFF" size={30} />
              ) : (
                `${userBankName}`
              )}
            </h4>
            {userBankName && (
              <div className="mt-4 flex justify-start gap-3 items-center">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                  className="h-[18px] w-[18px]"
                />
                <h4 className="text-[13px] text-gray-700  font-sora font-semibold">
                  Make default bank
                </h4>
              </div>
            )}

            <button
              onClick={() => {
                const requestData = {
                  accountNumber: bankNumber,
                  bankCode: bankCode,
                  is_default: false,
                };
                if (isChecked) {
                  requestData.is_default = true;
                }
                completeAddBank.mutate(requestData);
              }}
              disabled={disabled}
              className={
                disabled
                  ? "w-full h-[52px] flex justify-center items-center font-manrope mx-auto text-gray-200  rounded-xl bg-gray-400   mt-8"
                  : "w-full h-[52px] flex justify-center items-center font-manrope mx-auto  font-medium rounded-xl text-white bg-secondary_button mt-8"
              }
            >
              {completeAddBank.isPending ? (
                <ClipLoader color="#FFFFFF" size={30} />
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBanks;
