import React, { useState } from "react";
import { TailSpin } from "react-loader-spinner";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Register from "./Register";
import { API_URL } from "../constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth-context";
import OtpInput from "react-otp-input";
import { smallLogo } from "../assets/images";
import toast from "react-hot-toast";

const ForgotPasswordSuccess = () => {
  const { resetEmail } = useAuth();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [viewPassword, setViewPassword] = useState<boolean>(false);
  const [viewConfirmedPassword, setViewConfirmedPassword] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");

  const handleChange = (otp: React.SetStateAction<string>) => {
    setOtp(otp);
  };

  const validateEmail = (password: string, confirmPassword: string) => {
    // check if password is empty
    if (!password) {
      toast.error("kindly add your desired password");

      return false;
    }
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      toast.error(
        "Password must contain atleast 8 characters, 1 uppercase, 1 lowercase and 1 special character"
      );

      return false;
    }
    // check if confirmed password is empty
    if (!confirmPassword) {
      toast.error("Retype your password in the field above");

      return false;
    }

    if (confirmPassword !== password) {
      toast.error("Oops!!! Your passwords do not match");

      return false;
    }
    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateEmail(password, confirmPassword)) {
      return;
    }

    if (!loading) {
      const realOtp = otp;
      setLoading(true);
      axios
        .post(`${API_URL}api/auth/reset-password`, {
          otp: realOtp,
          email: resetEmail,
          newPassword: password,
        })
        .then((r: any) => {
          navigate("/login");
          toast.success("Password changed successfully");
          setLoading(false);
        })
        .catch((e: any) => {
          toast.error(e.response.data.message);
          setLoading(false);

          console.log(e.response.data.message);
        });
    }
  };

  return (
    <div className="w-full font-manrope lgss:h-full lgss:bg-[#C6D0D7] pt-20 flex flex-col justify-center items-center lgss:pt-0 lgss:w-3/5">
      <div>
        <img src={smallLogo} className="lgss:hidden" alt="" />
      </div>
      <div className="w-full px-8 lgss:px-24 pt-16 lgss:pt-0">
        <h2 className="text-text_black font-sora text-2xl font-bold pt-4 lgss:pt-0">
          Reset your password
        </h2>
        <h4 className="font-manrope text-text_black font-medium text-lg  pt-10 lgss:pt-6">
          Yaay, welcome back, please input your new password and confirm
        </h4>

        <div
          className={
            "lgss:h-[370px] lgss:mt-8 lgss:px-8 pt-8 w-full lgss:w-4/5 lgss:rounded-xl lgss:bg-white"
          }
        >
          <form onSubmit={handleSubmit} className=" w-full  ">
            <div className=" flex justify-center items-center">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                isInputSecure
                isInputNum
                inputStyle={{
                  width: "50px",
                  marginLeft: "4px",
                  marginRight: "4px",
                  height: "56px",
                  borderWidth: "2px",
                  borderColor: "#AEBCC6",
                  borderStyle: "solid",
                  backgroundColor: "transparent",
                  borderRadius: "0.75rem",
                  textAlign: "center",
                  fontWeight: "600",
                  fontSize: "1.25rem",
                  appearance: "none",
                  transition: "all 0.15s ease-in-out",
                  outline: "none",
                }}
                focusStyle={{
                  borderColor: "#0B84CB",
                }}
                shouldAutoFocus
              />
            </div>
            <label className="relative  flex items-center ">
              <input
                type={viewPassword ? "text" : "password"}
                value={password}
                placeholder="New Password"
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                className="h-[50px] w-full mt-8 px-[16px] font-manrope border-2 rounded-lg border-[#4A4A68] outline-none focus:border-blue-600 text-[#4A4A68] transition duration-200"
              />
              <span className="text-base mt-8 text-[#4A4A68] text-opacity-0 left-4 pt-3   px-[2px] transition duration-200 input-text absolute">
                New Password
              </span>
              {!viewPassword ? (
                <AiOutlineEyeInvisible
                  className="text-3xl -ml-10 mt-[30px] text-secondary_button"
                  onClick={() => setViewPassword((prev) => !prev)}
                />
              ) : (
                <AiOutlineEye
                  className="text-3xl -ml-10 mt-[30px] text-secondary_button"
                  onClick={() => setViewPassword((prev) => !prev)}
                />
              )}
            </label>
            <label className="relative  flex items-center ">
              <input
                type={viewConfirmedPassword ? "text" : "password"}
                value={confirmPassword}
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                name="Confirm password"
                className="h-[50px] w-full mt-8 px-[16px] font-manrope border-2 rounded-lg border-[#4A4A68] outline-none focus:border-blue-600 text-[#4A4A68] transition duration-200"
              />
              <span className="text-base mt-8 text-[#4A4A68] text-opacity-0 left-4 pt-3   px-[2px] transition duration-200 input-text absolute">
                Confirm Password
              </span>
              {!viewConfirmedPassword ? (
                <AiOutlineEyeInvisible
                  className="text-3xl -ml-10 mt-[30px] text-[#3A4852]"
                  onClick={() => setViewConfirmedPassword((prev) => !prev)}
                />
              ) : (
                <AiOutlineEye
                  className="text-3xl -ml-10 mt-[30px] text-[#3A4852]"
                  onClick={() => setViewConfirmedPassword((prev) => !prev)}
                />
              )}
            </label>

            <button
              type="submit"
              className="w-full h-[60px] flex justify-center items-center font-manrope font-medium rounded-xl text-white bg-secondary_button mt-8 lgss:mt-8"
            >
              {loading ? (
                <TailSpin color="#FFFFFF" height={30} width={30} />
              ) : (
                "Reset Password"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

const ResetPassword = () => (
  <div className="lgss:flex lgss:flex-row h-screen">
    <Register />
    <ForgotPasswordSuccess />
  </div>
);
export default ResetPassword;
