import React, { useEffect, useState } from "react";
import useAuthAxios from "../../utils/baseAxios";
import { formatAmount } from "../../utils/wordFunctions";
import { HiOutlineArrowLeft } from "react-icons/hi2";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { API } from "../../constant/api";
import { errorMessage } from "../../utils/errorMessage";
import { useUser } from "../../context/user-context";
import PaymentDeetTable from "./PaymentDeetTable";
import { BiExport } from "react-icons/bi";
import { CSVLink } from "react-csv";

const PaymentDeets = ({ setOpenTxn }: any) => {
  // const [screen, setScreen] = useState<number>(1);
  const { paymentName, setPaymentName } = useUser();
  const axiosInstance = useAuthAxios();
  const { paymentRefId } = useUser();

  const getPaymentDeets = async () => {
    const response = await axiosInstance.get(
      API.getPaymentTxn(paymentRefId, "", "", 10, 1)
    );
    return response?.data?.data;
  };

  const { data: transactions, error: error1 } = useQuery({
    queryKey: ["get-payment-deets"],
    queryFn: getPaymentDeets,
    retry: 1,
  });
  const totalDeposits = transactions?.pagination.totalDeposits || 0;
  const getPaymentFullDeets = async () => {
    const response = await axiosInstance.get(
      API.getPaymentTxn(paymentRefId, "", "", totalDeposits, 1)
    );
    return response?.data?.data;
  };

  const { data: fullTransactions, error: error2 } = useQuery({
    queryKey: ["get-full-payment-deets", totalDeposits],
    queryFn: getPaymentFullDeets,
    retry: 1,
    enabled: !!totalDeposits,
  });

  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
    if (error2) {
      const newError = error2 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1, error2]);

  const paymentsData = fullTransactions?.deposits;
  const totalUsdtAmount = paymentsData?.reduce(
    (total: any, deposit: { usdtAmount: any }) =>
      total + (deposit.usdtAmount || 0),
    0
  );
  const totalNairaValue = paymentsData?.reduce(
    (total: number, deposit: any) => {
      // Find the USDT rate in the deposit's rates array
      const coinRate = deposit?.rates?.find(
        (rate: { abbr: string }) => rate.abbr?.toLowerCase() === "usdt"
      )?.rate;

      // If rate exists, calculate the Naira value and add it to the total
      return total + (deposit.usdtAmount || 0) * (coinRate || 0);
    },
    0 // Initial value for reduce
  );

  const exportData = fullTransactions?.deposits?.map((data: any) => {
    // Find the USDT rate for the current deposit
    const coinRate = data?.rates?.find(
      (rate: { abbr: string }) => rate.abbr?.toLowerCase() === "usdt"
    )?.rate;

    // Calculate the Naira value
    const amountInNaira = coinRate ? data.usdtAmount * coinRate : 0;

    // Format the data for export
    return {
      txnid: `${data.txid}`,
      coin: `${data.currency}`,
      network: `${data.network}`,
      toAddress: `${data.toAddress}`,
      amount: `${formatAmount(data.amount)}`,
      amountInUsd: `${formatAmount(data.usdtAmount)}`,
      amountInNaira: `${formatAmount(amountInNaira)}`,
      dateCreated: `${(() => {
        const dateString = data.processedAt;
        const date = new Date(dateString);

        const day = date.getDate();
        const ordinalSuffix = (day: number) => {
          switch (day) {
            case 1:
            case 21:
            case 31:
              return "st";
            case 2:
            case 22:
              return "nd";
            case 3:
            case 23:
              return "rd";
            default:
              return "th";
          }
        };

        const formattedDate = `${date.toLocaleString("default", {
          month: "short",
        })} ${day}${ordinalSuffix(
          day
        )}, ${date.getFullYear()}, ${date.toLocaleString("default", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        })}`;

        return formattedDate;
      })()}`,
    };
  });
  return (
    <div className="w-full h-full pt-16 px-6 pb-20  font-manrope overflow-auto  lgss:px-20  lgss:w-5/6">
      <div className="flex md:justify-between flex-col md:flex-row justify-start items-start md:tems-center">
        <div className="flex justify-start gap-2 items-center ">
          <HiOutlineArrowLeft
            onClick={() => {
              setPaymentName("");
              setOpenTxn(false);
            }}
            className="text-[#3A4852] cursor-pointer text-[20px]"
          />
          <h2 className="font-sora text-[24px] font-semibold text-[#3A4852]">
            {paymentName}
          </h2>
        </div>
      </div>
      <div className="bg-white mt-10 py-5 rounded-xl lgss:py-8 px-4 lgss:px-8">
        <div className="flex justify-between items-center ">
          {/* <div className="flex justify-center items-center gap-4 ">
                <select
                  onChange={(e) => setFilter(e.target.value)}
                  value={filter}
                  className="px-4 border bg-transparent outline-none  rounded-xl font-semibold text-[#3A4852] text-[11px] border-gray-300  h-[36px]"
                >
                  <option value="">All Time</option>
                  <option value="today">Today</option>
                  <option value="thisWeek">This week</option>
                  <option value="last3Months">Last 3 months</option>
                </select>
              </div> */}
        </div>
        <div className="w-full mt-6 flex justify-start items-center flex-col lgss:flex-row">
          <div className="pb-4 border  border-gray-100 w-full  lgss:w-1/3 shadow-sm rounded-xl bg-white px-4 pt-4">
            <h4 className="text-[14px]  text-secondary_button tracking-wider font-semibold">
              TOTAL PAYOUTS RECEIVED
            </h4>
            <div className="w-full mt-4 h-[1px] bg-[#F1F3F5]" />
            <h2 className="text-[22px] font-sora font-bold text-[#3A4852] mt-2">
              {`$${formatAmount(totalUsdtAmount) ?? 0.0}`}
            </h2>
            <div className="w-full flex px-8 lgss:px-4 justify-between items-center rounded-full mt-4 h-[26px] bg-[#F7F8F9]">
              <h4 className="text-[12px] lgss:text-[11px] font-bold text-[#617889]">
                {`${formatAmount(totalNairaValue) ?? 0.0} NGN`}
              </h4>
              <div className=" w-[1px] bg-[#617889] h-[16px]" />
              <h4 className="text-[12px] lgss:text-[11px] font-bold text-[#617889]">
                {`${formatAmount(paymentsData?.length) ?? 0.0} `}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white mt-10 rounded-xl py-5 lgss:py-8 px-4 lgss:px-8">
        <div className="w-full flex justify-between items-center">
          <h2 className=" text-[18px] font-bold text-[#3A4852]">
            Recent activities
          </h2>
          <CSVLink
            data={exportData ?? ""}
            filename={`${paymentName}-transaction-list`}
            className="flex gap-1 items-center bg-secondary_button p-[11px] rounded-lg"
          >
            <BiExport className="text-[white]" />
            <h4 className="uppercase font-sora font-semibold mt-1 text-gray-200 text-[10px]">
              Export
            </h4>
          </CSVLink>
        </div>
        {/* <div className="w-full flex justify-between items-center mt-6">
          <div className="w-full md:w-8/12 lgss:w-[23%] xxl:w-[20%] bg-[#F1F3F5] mx-auto lgss:mx-0 h-[60px] flex p-2 items-center justify-center rounded-full ">
            <button
              onClick={() => setScreen(1)}
              className={
                screen === 1
                  ? " text-[11px] font-bold text-white bg-gray-700 rounded-full h-full w-1/2"
                  : " text-[11px] font-bold text-gray-700  rounded-full h-full w-1/2"
              }
            >
              Transaction
            </button>
            <button
              onClick={() => setScreen(2)}
              className={
                screen === 2
                  ? " text-[11px] font-bold text-white bg-gray-700 rounded-full h-full w-1/2"
                  : " text-[11px] font-bold text-gray-700  rounded-full h-full w-1/2"
              }
            >
              All Activities
            </button>
          </div>
        </div> */}
        <div className="mt-10">
          <PaymentDeetTable />
        </div>
      </div>
    </div>
  );
};

export default PaymentDeets;
