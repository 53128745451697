export const truncateWord = (word: string): string => {
  if (word.length <= 10) {
    return word; // No need to truncate if the word is already short
  }

  const firstPart = word.slice(0, 6);
  const lastPart = word.slice(-3);

  return `${firstPart}......${lastPart}`;
};
export const truncateWordd = (word: string): string => {
  if (word.length <= 10) {
    return word; // No need to truncate if the word is already short
  }

  const firstPart = word.slice(0, 15);
  const lastPart = word.slice(-9);

  return `${firstPart}......${lastPart}`;
};

 export function formatAmount(value: any) {
    if (value === null || value === undefined) return "0.00"; // Handle null/undefined values

    // Convert to a number if it's a string
    const numValue = Number(value);

    // If value is greater than 0.01, format with 2 decimal places
    if (numValue >= 0.01) {
      return numValue.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

    // If value is smaller than 0.01, but not zero, format with higher precision
    if (numValue > 0) {
      const precision = Math.abs(numValue) < 0.0001 ? 8 : 3; // More precision for very small values
      return numValue.toFixed(precision);
    }

    // Return '0.00' for zero value
    return "0.00";
  }


  export function formatDateTime(dateTime: string): string {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  const date = new Date(dateTime);
  const month = months[date.getMonth()];
  const day = date.getDate();
  
  const hours = date.getHours();
  const minutes = date.getMinutes();
  
  // Format hours and minutes to 12-hour format
  const formattedHours = hours % 12 || 12; // Convert 0 -> 12 for AM/PM
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  return `${month} ${day} | ${formattedHours}:${formattedMinutes}${ampm}`;
}