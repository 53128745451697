import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";
import { FiCopy } from "react-icons/fi";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";
import { ClipLoader } from "react-spinners";
import { comingSoon } from "../../assets/images";
import GetRates from "../../components/utils/GetRates";
import { APP_SINGLE_BUSINESS_URL } from "../../constant";
import { API } from "../../constant/api";
import { useUser } from "../../context/user-context";
import useAuthAxios from "../../utils/baseAxios";
import { errorMessage } from "../../utils/errorMessage";
import { formatAmount, formatDateTime } from "../../utils/wordFunctions";
import PaymentModal from "./PaymentModal";
import SinglePaymentModal from "./SinglePaymentModal";

const PaymentDeetTable = () => {
  const { paymentRefId } = useUser();
  const [page, setPage] = useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any[]>([]);
  const axiosInstance = useAuthAxios();
  const getPaymentDeets = async () => {
    const response = await axiosInstance.get(
      API.getPaymentTxn(paymentRefId, "", "", 10, page)
    );
    return response?.data?.data;
  };

  const {
    data: transactions,
    error: error1,
    isLoading: loading1,
  } = useQuery({
    queryKey: ["get-payment-deets", page],
    queryFn: getPaymentDeets,
    retry: 1,
  });

  useEffect(() => {
    if (error1) {
      const newError = error1 as any;
      toast.error(errorMessage(newError?.message || newError?.data?.message));
    }
  }, [error1]);
  //   useEffect(() => {
  //     const debounceTimer = setTimeout(() => {
  //       refetch1();
  //     }, 600);

  //     return () => {
  //       clearTimeout(debounceTimer);
  //     };
  //   }, [query, refetch1]);
  // console.log(transactions);
  const paymentsData = transactions?.deposits;
  const rate = GetRates("USDT");
  const totalPages = transactions?.pagination.totalPages || 0;

  return (
    <div className="w-full rounded-xl mt-12  border border-gray-100">
      {loading1 ? (
        <div className="w-full mt-16 flex justify-center items-center">
          <ClipLoader color="#0B84CB" size={80} />
        </div>
      ) : (
        <div className="w-full">
          {paymentsData?.length >= 1 ? (
            <div className="w-full overflow-auto h-[600px]">
              <div className="w-full  hidden lgss:flex items-center lgss:gap-8 py-4 px-6 bg-gray-50 rounded-t-xl">
                <h4 className=" text-gray-700 font-bold text-[14px] text-left w-1/4">
                  TransactionID
                </h4>

                <h4 className=" text-gray-700 font-bold text-[14px] text-left w-1/4">
                  Amount
                </h4>

                <h4 className=" text-gray-700 font-bold text-[14px] text-left w-1/4">
                  Naira Value
                </h4>
                <h4 className=" text-gray-700 font-bold text-[14px] text-left w-1/4">
                  Date
                </h4>
              </div>
              {paymentsData?.map((data: any, index: number) => (
                <div
                  key={index}
                  onClick={() => {
                    setModalData(data);
                    setOpenModal(true);
                  }}
                  className="px-6 bg-white flex  border-b border-gray-200 lgss:gap-4 py-4 lgss:py-4 flex-col gap-2  lgss:flex-row"
                >
                  <div className="w-full lgss:w-1/2 gap-4  flex lgss:gap-6 justify-between items-center lgss:justify-start">
                    <h4 className=" text-gray-700 font-medium whitespace-nowrap overflow-hidden text-[14px] text-left w-1/2">
                      {data?.txid}
                    </h4>
                    <h4 className=" text-gray-700 font-medium whitespace-nowrap overflow-hidden text-[14px] text-right lgss:text-left w-1/2">
                      {data?.amount ? formatAmount(data?.amount) : "----"}
                      {data?.currency?.toUpperCase()}
                    </h4>
                  </div>

                  <div className="w-full lgss:w-1/2 flex lgss:gap-6 justify-between items-center lgss:justify-start">
                    <h4 className=" text-gray-700 whitespace-nowrap overflow-hidden font-medium text-[14px] text-left w-1/2">
                      {data?.amount
                        ? formatAmount(data?.amount * rate)
                        : "----"}{" "}
                      NGN
                    </h4>
                    <h4 className=" text-gray-700 whitespace-nowrap overflow-hidden font-medium text-[14px] text-right lgss:text-left w-1/2">
                      {data?.processedAt
                        ? formatDateTime(data?.processedAt)
                        : "----"}{" "}
                    </h4>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full h-[620px] lgss:h-[600px] mb-20 px-6 lgss:px-20 lgss:mb-0 mt-20 pt-16 lgss:w-11/12 flex flex-col items-center justify-start  bg-white rounded-xl ">
              <img src={comingSoon} alt="" />
              <h2 className="font-sora text-[20px] font-bold mt-4 text-[#3A4852]">
                No transaction for this link yet
              </h2>
              <p className="text-center text-[#3A4852] font-medium text-[14px] mt-4">
                All transaction will appear here.
              </p>
            </div>
          )}
        </div>
      )}
      {paymentsData?.length >= 1 && (
        <div className="w-full  flex items-center justify-center lgss:justify-start lgss:gap-4 py-4 px-6 bg-gray-50 rounded-b-xl">
          <div className="flex gap-2  justify-end items-center">
            {/* <h4 className="font-bold font-sora text-[14px] text-white">
              Page {currentpage} of {totalPages}
            </h4> */}

            <div
              onClick={() => {
                if (page > 1) {
                  setPage((prev) => prev - 1);
                }
              }}
              className="w-[36px] cursor-pointer h-[36px] rounded-lg bg-white border border-gray-200 flex justify-center items-center "
            >
              <GoChevronLeft className="text-[18px] text-gray-700" />
            </div>
            <div className="w-[36px] cursor-pointer h-[36px] rounded-lg text-gray-500 bg-gray-100 border  text-[14px] font-bold flex justify-center items-center ">
              {page}
            </div>
            <div
              onClick={() => {
                if (page < totalPages) {
                  setPage((prev) => prev + 1);
                }
              }}
              className="w-[36px] cursor-pointer h-[36px] rounded-lg bg-white border border-gray-200 flex justify-center items-center "
            >
              <GoChevronRight className="text-[18px] text-gray-700" />
            </div>
          </div>
        </div>
      )}
      {openModal && (
        <SinglePaymentModal setOpenModal={setOpenModal} modalData={modalData} />
      )}
    </div>
  );
};

export default PaymentDeetTable;
